/* DROPDOWNS */

@mixin vw-dd-image-item-props($init-color) {
    @include link-no-decoration($init-color);
    padding-left: 5px;
    padding-right: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    line-height: 40px;
    white-space: nowrap;

    display: flex;
    align-items: center;

    .icon {
        font-size: var(--vcs-icon-size-md);
        margin: 0 7px;
    }

    &:hover {
        background-color: transparent;
    }
}

.vcs-ui__mobile-global-tools {
    /* stylelint-disable-next-line */
    button[aria-haspopup='true'] {
        color: white;

        > i {
            font-size: 32px;
        }
    }
}

.vw-dd-black {
    background-color: $black;
    margin: 0;
    padding: 0 10px;
    border: 0;
    border-radius: 0;

    .ctx-menu__sep {
        border-color: $vw-grey;
        margin: 0 -10px;
    }
}

.vw-dd-image-item {
    @include vw-dd-image-item-props($vw-grey);

    &:hover {
        color: $white;
        background-color: transparent;
    }
}

.override-bootstrap-dropdown {
    margin: 0;
    padding: 0;
    border-radius: 0;
    border: 1px solid $border-color;
}

/* SELECTS */

/* used in the thumbs-sorter */
.vw-select {
    height: 34px;
    padding: 0 7px;
    line-height: 32px;
    background-color: $input-bg-color;

    font-size: 13px;

    border: 1px solid $input-border-color;
    border-radius: 2px;

    cursor: pointer;

}

.vw-select-no-arrow {
    /* stylelint-disable */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
        display: none; /* remove default arrow in IE 10 and 11 */
    }
    /* stylelint-enable */
}

.permission-dropdown-bs {
    .dropdown-toggle {
        @include link-no-decoration($text-primary-color);
        height: 32px;
        padding: 0 5px;
        background-color: white;

        display: flex;
        align-items: center;
    }

    &:hover {
        outline: 1px solid $border-color;
    }

    .icon {
        font-size: var(--vcs-icon-size-md);
        color: $text-primary-color;
    }

    li {
        @include ctx-menu-item--detail;
        @include vw-hover-animation($text-primary-color);

        min-width: 250px;
        margin: 15px 10px;
        cursor: pointer;

        .info {
            margin-left: 7px;
        }
    }
}
