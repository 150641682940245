/* Collapsible elements */

.vw-collapse-color-expanded[aria-expanded='true'] {
    @include item-selected();
}

@mixin vw-collapse {
    @include text-collapse();
    display: block;
    width: 100%;
    text-transform: uppercase;

    &:hover {
        cursor: pointer;
    }

    .icon-next-prev-16 {
        display: inline-block;
        font-size: 16px !important;
        padding: 4px;
    }

    .text {
        margin-left: 5px;
        word-break: normal;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .version-name {
        color: $text-secondary-color;
        font-size: 14px;
        text-transform: none;
        font-weight: bold;
        margin-right: 5px;
    }

    .vw-collapse-arrow {
        float: left;
        color: $vw-grey;
        margin: 0 10px;
    }

    &[aria-expanded='true'] {
        .icon-next-prev-16 {
            transform: rotate(90deg);
        }
    }
}

.vw-collapse {
    @include link-no-decoration($text-primary-color);
    @include vw-hover-animation($vw-grey);
    @include vw-collapse();
}

.vw-collapse-white {
    @include link-no-decoration(white);
    @include vw-collapse();
}

.vw-collapse-sm {
    font-size: 12px;
    line-height: 30px;
}

.vw-collapse-md {
    font-size: 13px;
    line-height: 36px;
}

.vw-collapse-lg {
    padding: 15px 0;
    font-size: 14px;
    border-bottom: 1px solid $border-color;
    line-height: 22px;

    &[aria-expanded='true'] {
        border-bottom: 0;
    }
}

.vw-collapse-duo {
    line-height: 50px;

    .text-prim {
        font-size: 14px;
        width: 130px;
        color: $text-primary-color;
        display: inline-block;
    }

    .text-secondary {
        font-size: 12px;
        color: $text-secondary-color;
        text-transform: none;
    }
}

.collapse-content {
    padding: 0 23px;
}

.collapse-to-target {
    position: fixed;
    transition: all 0.4s ease-out;
    transform-origin: left top;
    z-index: 9999;
}

.collapse-to-target.collapse {
    opacity: 0;
    animation: collapse-to-target 0.4s;
}
