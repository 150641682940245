@use 'sass:math';

@import '~@vectorworks/vcs-ui/dist/colors.css';

:root {
    --vcs-icon-size-sm: 16px;
    --vcs-icon-size-md: 24px;
}

$white: #fff;
$black: #000;

$page-bg-color: #fcfafa;
$file-bg-color: $white;

$navbar-height: 73px;
$navbar-height-xs: 56px;
$gravatar-size: 32px;

/* Sidebar */
$sidebar-width: 225px;
$sidebar-link-height: 48px;
$sidebar-icon-size: 32px;
$sidebar-icon-padding: math.div($sidebar-link-height, 2) - math.div($sidebar-icon-size, 2);

/* Font and border colors */
$vw-annual-color: #00bcb4;
$vw-annual-border: darken($vw-annual-color, 10%);
$vw-annual-color-orange: #ff8200;
$vw-annual-color-orange-border: darken($vw-annual-color-orange, 10%);
$vw-annual-color-grey: #898d8d;

$border-color: #e9e9e9;
$text-primary-color: #605d62;
$text-secondary-color: #bababa;
$thumb-shadow-color: #d6d6d6;
$thumb-shadow: 1px 1px 1px $thumb-shadow-color, -1px 0 1px $thumb-shadow-color;
$vw-grey: #898d8d;
$vw-slate-grey: #707070;
$vw-dark-grey: #333;

/* Inputs and selects props */
$input-bg-color: #f7f7f8;
$input-border-color: #dfdfdf;
$di-input-height: 32px;

$vw-green: #2ab572;
$vw-light-green: #caecdc;
$vw-red: #ee2524;
$vw-yellow: #b08a04;
$vw-light-red: lighten(desaturate($vw-red, 67%), 5%);

/* Screen sizes */
$xs-max-width: 767px;
$sm-min-width: 768px;
$sm-max-width: 991px;
$md-min-width: 992px;
$md-max-width: 1199px;
$lg-min-width: 1200px;

/* Breadcrumbs and Tools variables */
$toolbar-height: 64px;
$toolbar-height-xs: 53px;
$tool-margin-top: 10px;
$tool-size: 30px;
$brcr-size: 36px;


/* Dialogs variables */
$di-header-height: 48px;
$di-btns-height: 71px;
$di-padding-inner: 15px;
$di-block-margin-top: 15px;
$di-block-margin-bot: 25px;

$di-border: #676464;
$di-header-border: #e9e8e8;
$di-btns-bg: #f3f3f3;
$di-btn-bg: #8a8b8c;
$di-btn-height: 32px;

/* Z-indexes of main elements */
$global-z: 'main', 'ib__editor', 'map-editor', 'ib__fs', 'fileview', 'dialog', 'react-dialog';
 
$main-z: 'add-slide-tool', 'thumb-sorter', 
    'gl-create-actions-widget', 'toolbar', 'mobile-search',
    'upload-overlay', 'lds', 'sidebar', 'header';

$iboards-editor-z: 'main', 'transition-overlay', 'ib-file-btn', 'navigation', 'carousel', 'map-widget', 'panel', 'toolbar', 'fileview';

// PANEL_BUFF is used for the overlapping functionality of panels
// The panels overlap eachother programatically based on the order
// that the user has opened them. Both of the panels should be under the
// toolbar so that the collapsed context tools that are visible over them

$fileview-z: 'overlay', 'controller', 'panel', 'PANEL_BUFF', 'toolbar', 'lds';

$map-editor-overlay-z: 'main', 'toolbar';
$map-editor-z: 'main', 'ib-file-btn', 'navigation', 'carousel';

// Individual dialogue z-indexed should be determined in the dialogue's
// scss file and used locally, since dialog__root creates stacking context

$dialog-z: 'selectize-dropdown';

$flying-notification-z-index: 80;
