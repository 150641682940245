/* ---------- Footer styles ---------- */
$footer-background-color: #8a8b8c;

/* stylelint-disable */
footer.container-fluid {
    @include respond-to-at-least(tablet) {
        text-align: center;
        padding: 35px 15px;
    }

    background-color: $footer-background-color;
    color: $white;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 35px 50px;
    font-size: 12px;
    line-height: 17px;

    a {
        @include link-no-decoration($white);
    }

    ul,
    li {
        margin: 0;
        list-style: none;
    }

    #vw-info {
        .icon-vw-logo {
            @include respond-to-at-least(tablet) {
                display: inline-block;
                margin-left: -240px;
            }
            font-size: 52px;
            line-height: 45px;
            height: 45px;
            display: block;
            position: relative;
            top: 0;
        }

        .icon-vw-logo::before {
            line-height: 0;
            position: absolute;
            top: 23px;
            left: 0;
        }

        #address {
            position: relative;
            margin: 10px 0;
        }

        #social ul li {
            float: left;
            margin-right: 8px;
        }

        #social ul {
            @include respond-to-at-least(tablet) {
                display: inline-block;
            }
        }

        #social .icon {
            font-size: 21px;
            position: relative;
        }
    }

    #footer-nav {
        @include respond-to-at-least(tablet) {
            margin-top: 30px;
        }
        li {
            margin-bottom: 8px;
        }

        a:hover {
            text-decoration: underline;
        }
    }


    #distributor-info {
        @include respond-to-at-least(tablet) {
            margin-top: 30px;
            text-align: center;
        }
        float: left;
        text-align: right;

        #distr_img {
            height: 40px;
        }

        #authorized_info {
            text-transform: uppercase;
        }

        #distr_contact_info p {
            @include respond-to-at-least(tablet) {
                width: 100%;
            }
            width: 60%;
            float: right;
        }
    }
}

.footer-vertical.dropdown-menu {
    @include respond_to(phone) {
        position: fixed;
        top: $navbar-height-xs;
        bottom: 0;
        overflow: auto;
    }
}

.footer-vertical {
    width: $sidebar-width;
    padding: 0 15px !important;
    margin: 0;
    background-color: $vw-grey;
    color: white;
    list-style-type: none;

    .ico-link {
        @include link-no-decoration($white);
    }

    .links-section {
        padding: 10px 0;
        border-bottom: 1px solid #9e9d9e;
        font-size: 13px;
    }

    .social-icons {
        font-size: 20px;
        margin: 0 3px;
        cursor: pointer;
    }

    .text-link {
        @include link-no-decor-no-bg-color($white);
        display: block; /* to make them on new lines each */
        padding: 5px 0;
        font-size: 14px;
        text-transform: uppercase;

        &:hover {
            background-color: transparent;
        }
    }
}
/* stylelint-enable */