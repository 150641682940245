#dialog-feedback {
    .category {
        @include respond-to(phone) {
            display: block;
        }
        padding-right: 30px;
    }

    .dialog-inner {
        padding: 0 $di-padding-inner;
    }

    #categories {
        margin: 12px 0;
    }

    #feedback_text {
        width: 100%;
        min-height: 200px;
        border: 1px solid $border-color;
    }
}
