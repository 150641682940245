/* Avatars, created from user initials */

.avatar {
    width: $gravatar-size;
    height: $gravatar-size;
    min-width: $gravatar-size;
    border: 2px solid $white;
    border-radius: 50%;
    line-height: $gravatar-size - 4px; /* because of two times the border */
    text-align: center;
    color: $white;
    font-weight: bold;
    font-size: 11px;
    background-color: $vw-grey;
    background-size: cover;
}

.no-account-avatar {
    color: $text-primary-color;
}

.avatar-info {
    @include primary-secondary-canvas(12px, 16px, 11px, 14px);
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
}
