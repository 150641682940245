.login-footer {
    margin: auto;
}

.login-ftr-items {
    @include respond_to(phone) {
        flex-direction: column;
        text-align: center;
    }

    padding: 2%;
    display: flex;
    justify-content: center;
}

.login-ftr-item {
    @include respond_to(large_screen) {
        max-width: 160px;

        /* next two lines are IE hack to get max-width working */
        display: table;
        table-layout: fixed;
    }

    flex-direction: column;
    flex: 1;
    color: $text-primary-color;
    margin: 10px 25px;

    img {
        @include respond_to(phone) {
            width: 50px;
        }
        width: 85px;
    }

    h1 {
        @include respond_to(phone) {
            font-size: 15px;
            line-height: normal;
        }

        margin: 10px 0;
        text-transform: uppercase;

        font: {
            size: 17px;
            weight: bold;
        }
    }
}
