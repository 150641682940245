@use 'sass:math';

@import './variables';

$xs-max-width: 767px;
$sm-min-width: 768px;
$sm-max-width: 991px;
$md-min-width: 992px;
$md-max-width: 1199px;
$lg-min-width: 1200px;

@mixin respond-to($media-screen) {
    @if $media-screen == phone {
        @media only screen and (max-width: $xs-max-width) {
            @content;
        }
    } @else if $media-screen == tablet {
        @media only screen and (min-width: $sm-min-width) and (max-width: $sm-max-width) {
            @content;
        }
    } @else if $media-screen == small_screen {
        @media only screen and (min-width: $md-min-width) and (max-width: $md-max-width) {
            @content;
        }
    } @else if $media-screen == large_screen {
        @media only screen and (min-width: $lg-min-width) {
            @content;
        }
    }
}

@mixin respond-to-at-least($media-screen) {
    @if $media-screen == phone {
        @media only screen and (max-width: $xs-max-width) {
            @content;
        }
    } @else if $media-screen == tablet {
        @media only screen and (max-width: $sm-max-width) {
            @content;
        }
    } @else if $media-screen == small_screen {
        @media only screen and (max-width: $md-max-width) {
            @content;
        }
    } @else if $media-screen == large_screen {
        @media only screen and (min-width: $lg-min-width) {
            @content;
        }
    }
}

@mixin respond-to-at-most($media-screen) {
    @if $media-screen == phone {
        @media only screen and (min-width: $xs-min-width) {
            @content;
        }
    } @else if $media-screen == tablet {
        @media only screen and (min-width: $sm-min-width) {
            @content;
        }
    } @else if $media-screen == small_screen {
        @media only screen and (min-width: $md-min-width) {
            @content;
        }
    } @else if $media-screen == large_screen {
        @media only screen and (min-width: $lg-min-width) {
            @content;
        }
    }
}

@mixin respond-to-orientation($orientation) {
    @media screen and (orientation: $orientation) {
        @content;
    }
}

@mixin text-collapse {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin text-collapse-reverse {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    direction: rtl;
}

@mixin text-break {
    /* For Firefox */
    white-space: pre-wrap;
    word-break: normal;

    /* For Chrome and IE */
    word-wrap: break-word;
}

@mixin link-no-decoration($font-color) {
    color: $font-color;

    &:hover,
    &:visited,
    &:active,
    &:focus {
        color: $font-color;
        text-decoration: none;
        cursor: pointer;
    }
}

@mixin link-no-decor-no-bg-color($font-color) {
    color: $font-color;

    &:hover,
    &:visited,
    &:active,
    &:focus {
        color: $font-color;
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;
    }
}

@mixin upper-case-bold($font-size) {
    font-size: $font-size;
    text-transform: uppercase;
    font-weight: bold;
}

/* Common for all buttons. Usually used with @mixin link-no-decorations
   To add color to the text, then add color to the background.
   You should define the width and line-height, too.
*/
@mixin vw-btn {
    @include text-collapse();
    display: inline-block;
    border-radius: 2px;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    &[disabled] {
        pointer-events: none !important;
        opacity: 0.5;
    }
}

@mixin vw-btn-prim {
    background-color: $vw-annual-color;
    border: 1px solid $vw-annual-border;
    color: $white;
}

@mixin vw-btn-secondary {
    background-color: white;
    border: 1px solid $text-secondary-color;
    color: $text-primary-color;
}

@mixin icon-text-hover-item($class-name: icon) {
    @include vw-hover-animation($text-primary-color, $class-name);

    white-space: nowrap;

    display: flex;
    align-items: center;

    .#{$class-name} {
        font-size: var(--vcs-icon-size-md);
    }

    &:hover {
        cursor: pointer;
    }
}

@mixin ctx-menu-item($class-name: icon) {
    @include icon-text-hover-item($class-name);
    padding-right: 20px;
    line-height: 38px;

    .#{$class-name} {
        color: $text-primary-color;
        padding-left: 20px;
        padding-right: 10px;
    }
}

/* Aligns items vertically against their parent.
   Does not work in scenarios where you can'y use top property.
*/
@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin vw-dropdown-std($width, $height) {
    width: $width;
    height: $height;

    padding: 0 7px;
    border-color: $border-color;
    background-color: $white;
    color: $text-primary-color;
    font-size: 15px;
    line-height: $height;
    cursor: pointer;
    flex-shrink: 0;
}

@mixin vw-hover-animation($init-color, $class-name: icon) {
    transition: color 0.4s ease;
    color: $init-color;

    .#{$class-name} {
        transition: color 0.4s ease;
        color: $init-color;
    }

    &:hover {
        transition: color 0.25s ease;
        color: $vw-annual-color;

        .#{$class-name} {
            transition: color 0.25s ease;
            color: $vw-annual-color;
        }
    }
}

@mixin panel-animation($width, $direction) {
    @include respond-to(phone) {
        width: 90%;
        #{$direction}: -95%;
    }
    position: fixed;
    width: $width;
    #{$direction}: -$width - 5px;
    transition: $direction 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);

    &.expanded {
        #{$direction}: 0;
        transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

@mixin panel-shadow--left {
    border-left: 1px solid $border-color;
    box-shadow: -3px 3px 5px 0 rgba($black, 0.3);
}

@mixin panel-shadow--right {
    border-left: 1px solid $border-color;
    box-shadow: 3px 3px 5px 0 rgba($black, 0.3);
}

@mixin primary-secondary-canvas($prim-font-size, $prim-line-height, $sec-font-size, $sec-line-height) {
    overflow: hidden;

    .prim {
        @include text-collapse();
        font-size: $prim-font-size;
        line-height: $prim-line-height;
        color: $text-primary-color;
    }

    .secondary {
        @include text-collapse();
        font-size: $sec-font-size;
        line-height: $sec-line-height;
        color: $text-secondary-color;
    }
}

@mixin title-descr-canvas--small {
    .info {
        .title {
            color: $text-primary-color;
            font-weight: bold;
        }

        .descr {
            font-size: 11px;
            color: $text-secondary-color;
            white-space: normal;
        }
    }
}

@mixin ctx-menu-item--detail {
    @include title-descr-canvas--small;
    display: flex;
    line-height: normal;
    max-width: 300px;
    margin: 15px 0;
}

@mixin fit-background--cover {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin asset-list-item-thumb() {
    .asset-img {
        @include square(24px);
        @include fit-background--cover;
        position: absolute;
        top: 9px;
        left: 18px;
    }

    .asset-img-default {
        position: absolute;

        top: 9px;
        left: 18px;
        width: auto;
        height: auto;
        font-size: var(--vcs-icon-size-md);
    }

    .svg-file-icon {
        position: absolute;

        top: 9px;
        left: 18px;
    }

    .folder {
        top: 9px;
        left: 18px;
    }
}

@mixin z-index-stacking-context($elem) {
    position: relative;
    z-index: index($global-z, $elem) - 1;
}

@mixin sidepanel-toggling($width) {
    @media screen and (min-width: $md-min-width) {
        #sidebar-wrapper {
            display: block !important;
            width: $width !important;
        }

        .homepage,
        .google-pages,
        .onedrive-pages,
        .trash,
        .presentations-trash,
        .trash-folder,
        .shared-with-me,
        .shared-with-me-folder,
        .shared-with-me-owner,
        .shared,
        .browser,
        .task,
        .presentations,
        .presentation,
        .search {
            padding-left: $width !important;
        }

        .navbar-brand img {
            margin-left: 15px;
        }
    }

    #content.menu-displayed #sidebar-wrapper {
        display: block;
        width: $width;
    }

    #content.menu-displayed .menu-toggled-layer {
        @include respond_to(phone) {
            top: $navbar-height-xs !important;
        }

        position: absolute;
        display: block;
        top: $navbar-height;
        left: $width;
        right: 0;
        bottom: 0;
        background-color: $text-primary-color;
        z-index: index($main-z, 'sidebar');
        
        opacity: 0.2;
    }
}

@mixin item-selected {
    background-color: $vw-annual-color !important;
    border-bottom: 0 !important;
    color: white !important;

    .attr,
    .attr-primary,
    .attr-primary a,
    .attr-primary div,
    .duo-attr,
    .duo-attr a,
    .asset-img,
    .attr-primary,
    .duo-attr,
    .flags > .flag,
    .attr > .flag,
    .storage-icon,
    .icon,
    .prim,
    .secondary {
        color: white !important;
    }

    .attr-primary,
    .duo-attr {
        a:hover {
            color: white;
        }
    }
}

.item-selected {
    @include item-selected;
}

@mixin item-selected-medium {
    background-color: rgba($vw-annual-color, 0.08) !important;
    border-bottom: 0 !important;
    color: $vw-annual-color !important;

    .attr,
    .attr-primary,
    .attr-primary a,
    .attr-primary div,
    .duo-attr,
    .duo-attr a,
    .asset-img,
    .attr-primary,
    .duo-attr,
    .flags > .flag,
    .attr > .flag,
    .storage-icon,
    .icon,
    .prim,
    .secondary {
        color: $vw-annual-color !important;
    }

    .attr-primary,
    .duo-attr {
        a:hover {
            color: $vw-annual-color;
        }
    }
}

.item-selected-medium {
    @include item-selected-medium;
}

@mixin item-selected-light {
    background: rgba($vw-annual-color, 0.1) !important;

    .attr,
    .attr-primary,
    .attr-primary a,
    .attr-primary div,
    .duo-attr,
    .duo-attr a,
    .asset-img,
    .attr-primary,
    .duo-attr,
    .flags > .flag,
    .attr > .flag,
    .storage-icon,
    .icon,
    .prim,
    .secondary {
        color: $vw-annual-color !important;
    }

    /* stylelint-disable */
    .info-secondary,
    .info-secondary > .canvas-attr,
    .info-secondary > .canvas-flags > .flag {
        color: $vw-annual-color !important;
    }

    .svg-file-icon {
        svg, polygon, path:not([fill='none']) {
            fill: $vw-annual-color !important;
        }
    }
    /* stylelint-enable */

}

.item-selected-light {
    @include item-selected-light;

    .lds--grey {
        border-color: $vw-annual-color !important;
        border-top-color: transparent !important;
    }
}

@mixin item-disabled {
    .attr,
    .attr-primary,
    .attr-primary a,
    .attr-primary div,
    .duo-attr,
    .duo-attr a,
    .asset-img,
    .attr-primary,
    .duo-attr,
    .flags > .flag,
    .attr > .flag,
    .storage-icon,
    .icon,
    .prim,
    .secondary {
        opacity: 0.5;
    }

    /* stylelint-disable */
    .info-secondary,
    .info-secondary > .canvas-attr,
    .info-secondary > .canvas-flags > .flag {
        opacity: 0.5;
    }
    /* stylelint-enable */
}

.item-disabled {
    @include item-disabled;
}

@mixin thumb-selected-shadow($width, $blur) {
    box-shadow: $width $width $blur $width $vw-annual-color, -1*$width -1*$width $blur $width $vw-annual-color, $width -1*$width $blur $width $vw-annual-color, -1 * $width $width $blur $width $vw-annual-color;
}

@mixin scrollbars {
    ::-webkit-scrollbar {
        width: 15px;
        height: 15px;
        background-color: rgba(255, 255, 255, 0);
    }
     
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        background-clip: padding-box;
    }
     
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba($vw-grey, 0.75);

        &:hover {
            border: 4px solid transparent;
            background-color: rgba($vw-grey, 0.85);
        }
    }
}

/* stylelint-disable */
/*
 * Mixin to apply styles for Firefox browser only.
*/
@mixin only_ff {
	@-moz-document url-prefix() {
		@content;
	}
}

@mixin only_edge {
    @supports (display: -ms-grid) {
        @content;
    }
}
/* stylelint-enable */


@mixin shared-badge($x, $y, $icon-size: 16px) {
    .is-shared {
        left: 50%;
        top: 50%;
        transform: translate($x, $y);
        position: absolute;

        font-size: $icon-size;
        color: rgba($black, 0.3) !important;

        z-index: 1;
    }
}

@mixin permission-badge($x, $y, $icon-size: 16px) {
    $border-size: math.div($icon-size, 8);

    .permission-badge {
        width: $icon-size + $border-size * 2;
        height: $icon-size + $border-size * 2;

        top: 50%;
        left: 50%;
        transform: translate($x, $y);
        position: absolute;

        font-size: $icon-size;
        color: $white !important;
        background-color: $vw-grey;
        border: $border-size solid $page-bg-color;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        z-index: 1;
    }

    &.item-selected-light .permission-badge {
        background-color: $vw-annual-color;
        border: $border-size solid #e2f4f3;
    }
}

@mixin box-shadow {
    border-radius: 3px;
    box-shadow: $thumb-shadow;
}
