header {
    @include respond_to(phone) {
        height: $navbar-height-xs;

        .vw-portal-dropdown {
            height: $navbar-height-xs !important;
            width: $navbar-height-xs !important;
        }

        #portal-logo {
            display: none;
        }
    }

    height: $navbar-height;
    background-color: $black;
    color: $white;

    flex-shrink: 0;
    display: flex;
    justify-content: space-between;

    position: relative;
    z-index: index($main-z, 'header');

    .footer-vertical {
        border: 0;
        border-radius: 0;
    }

    .new-notifications {
        width: 10px;
        height: 10px;
        margin-left: 5px;
        border-radius: 50%;
        box-shadow: -0.707px 0.707px 2px 0 rgba(0, 0, 0, 0.72);
        background-color: $vw-annual-color;
    }

    .announcements-dot {
        width: 10px;
        height: 10px;
        margin-top: -19px;
        margin-left: -2px;
        border-radius: 50%;
        box-shadow: -0.707px 0.707px 2px 0 rgba(0, 0, 0, 0.72);
        background-color: $vw-annual-color;
    }

    .avatar {
        width: 32px;
        height: 32px;
        border: 2px solid $white;
    }

    .header-profile-dropdown-black {
        @include respond_to(phone) {
            left: auto;
        }

        right: 0;
        width: inherit;
    }

    .vw-portal-dropdown {
        @include link-no-decoration($white);
        width: 60px;
        height: $navbar-height;

        background-color: $vw-grey;
        font-size: 32px;
        float: left;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header-sign-in-button {
        @include respond_to(phone) {
            height: $navbar-height-xs;
        }

        height: $navbar-height;
        margin-right: 25px;
    }

    .si-btn {
        @include respond-to(phone) {
            min-width: 125px;
        }

        @include vw-btn();
        @include vw-btn-prim();
        @include vertical-align();
        padding: 0 10px;
        line-height: 32px;
        min-width: 140px;

        float: right;
    }
}

.header-lhs {
    flex-shrink: 0;
    display: flex;
}


#portal-logo {
    @include link-no-decoration($white);
    padding-left: 10px;

    display: flex;
    align-items: center;

    img {
        height: 42px;
    }
}

.navbar-link {
    @include respond_to(phone) {
        line-height: $navbar-height-xs;
    }
    @include link-no-decoration($white);

    line-height: $navbar-height;
    text-transform: uppercase;
    padding: 0 10px;
    float: left;
}

#navbar-icons {
    display: flex;

    .navbar-icon {
        display: flex;
        align-items: center;
        padding: 0 10px;
    }

    .icon {
        font-size: 32px;
    }

    .navbar-icon > a {
        @include link-no-decoration($white);
    }

    .lds--header {
        width: 20px;
        height: 20px;
    
        position: absolute;
        margin-left: 25px;
        margin-top: -12px;
    }
}
