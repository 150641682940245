@charset 'utf-8';

@import 'utils/variables';
@import 'utils/placeholders';
@import 'utils/mixins';
@import 'utils/common';
@import 'utils/consts_mixins';

@import 'form';
@import '~@vectorworks/vcs-icons/style.css';
@import 'cursors';

@import 'layout/header';
@import 'layout/footer';
@import 'layout/login-footer';

@import 'avatars';
@import 'cursors';
@import 'dropdowns';
@import 'form';
@import 'unsupported-browser';
@import 'welcome-tour';

@import 'dialogs/dialog';
@import 'dialogs/feedback';


/* The stylings below are setting height to the auto generated JS wrapper divs
   that load content. In order to scroll the files only, we have to give those
   a height, that when exceeded, a scrollbar appears.
*/

@font-face {
    font-family: 'dinCond';
    src: url('fonts/DinCond.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'dinCondLight';
    src: url('fonts/DinCond.ttf');
    font-weight: 300;
    font-style: normal;
}

html,
body {
    /* Reduce the default font size a bit when on mobile */
    @include respond_to(phone) {
        // font-size: 13px !important;
        // Prevent taphold on iPhone selecting text when entering the mobile-selection mode in list view.
        // stylelint-disable-next-line
        -webkit-user-select: none;
    }
    @include scrollbars;

    height: 100%;
    width: 100%;
    overflow: hidden;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

/* Safari disables selection by default */
[contenteditable=true] {
    user-select: text;
}

#content {
    @include z-index-stacking-context('main');

    height: 100%;
    display: flex;
    flex-direction: column;
}

app,
folder-link-preview,
ko-component-router,
ko-component-router > div,
#help-content {
    flex: 1;
    display: flex;
    overflow: auto;
}

app {
    position: relative;
    overflow-x: hidden;
}

.ko-component-router-view,
#help-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

#help-content #content-wrapper {
    -webkit-overflow-scrolling: touch;
}

#assets-wrapper {
    flex: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: $page-bg-color;

    position: relative;
    padding-top: 1px;
    touch-action: manipulation;
    user-select: none;

    .container-fluid {
        position: relative;
        padding-bottom: 50px;
    }
}

/* wrapper for pages like resources, downloads, etc */
.static-page {
    flex: 1;
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    user-select: auto;
}

.page-content {
    flex: 1 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.page-inner {
    flex: 1 0 auto;
    width: 100%;
    padding: 4%;
    color: var(--black);
}

a {
    @include link-no-decoration($vw-annual-color);
}

.message-box {
    @include respond-to-at-least(phone) {
        max-width: 85%;
    }

    margin: auto;
    padding: 10% 0;
    max-width: 450px;

    text-align: center;

    .icon {
        flex: 0 0 auto;
        color: $black;
        opacity: 0.1;
        font-size: 64px;
    }

    .message {
        display: flex;
        flex-direction: column;
        align-items: center;

        .msg-row-prim {
            font-weight: bold;
            color: var(--text-primary-color);
        }

        .msg-one-line {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: bold;
        }

        .msg-row-secondary {
            color: var(--text-secondary-color);
            font-size: 13px;
        }
    }
}

#id_languages {
    @include respond_to(phone) {
        margin-bottom: 5px;
    }
}

label {
    font-weight: normal !important;
}


.general-message-screen {
    display: flex;
    align-items: center;
    justify-content: center;
}

.italic {
    font-style: italic;
}

.assets-loading {
    height: 1px;
    margin-top: -1px;
    left: 0;
    right: 0;
    overflow: hidden;

    z-index: index($main-z, 'lds');

    &::before {
        display: block;
        position: absolute;
        content: '';
        left: -200px;
        width: 200px;
        height: 1px;
        background-color: $vw-annual-color;
        animation: loading 2s linear infinite;
    }
}

.bottom-loader {
    position: absolute;
    left: 0;
    right: 0;

    .assets-loading {
        position: absolute;
    }

    .text-loading-more {
        line-height: 49px;
        color: $text-secondary-color;
        font-size: 12px;
        text-align: center;
    }
}

@keyframes optimized-loading {
    0% { transform: translateX(-100%); }
    40% { transform: translateX(0); }
    60% { transform: translateX(0); }
    100% { transform: translateX(100%);  }
}

.line-loading {
    height: 1px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    transform: translateX(100%);
    animation: optimized-loading 1s linear infinite;
    animation-delay: 0.5s;
    background-color: $vw-annual-color;

    z-index: index($main-z, 'lds');
}

.line-loading--bot {
    bottom: -1px;
}
