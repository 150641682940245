.site-form label {
    color: #000;
    font-weight: bold;
    font-size: 14px;
    vertical-align: top;
}

.site-form .sublabel {
    color: #616265;
}

.site-form .required {
    color: #f00;
    font-size: 14px;
}

.site-form .invalid {
    color: $vw-red;
}

.site-form .form-table {
    margin-bottom: 40px;
}

.site-form .form-table .attachments {
    list-style-type: none;
    margin: 0;
    list-style-image: none;
}

/* stylelint-disable */
.site-form .form-table .attachments li {
    padding-left: 0.5em;
}
/* stylelint-enable */

.site-form .form-table tr {
    border: 0;
    vertical-align: top;
}

.site-form .form-table td {
    vertical-align: text-top;
    padding-bottom: 15px;
}

.site-form .form-table td,
.site-form .form-table tr {
    width: auto;
}

.site-form .form-table tbody {
    border: 0;
}

.site-form .form-table .label {
    padding-left: 0;
    padding-right: 15px;
}

.site-form .form-table .instructions {
    margin-bottom: 5px;
}

#email .form-submit,
#feedback .form-submit {
    font-size: 12px;
    line-height: 16px;
}

.no-bullets {
    list-style: none;
    list-style-image: none;
}

.site-form .email-filelink {
    display: inline-block;
    margin: 8px 0;
    font-size: 1.2em;
}

.site-form .filelink-icon {
    width: 17px;
    height: 17px;
}

#email .errorlist {
    display: inline-block;
}

#email #file_list {
    width: 300px;
}

input [type=checkbox],
input [type=radio] {
    width: auto;
}
