@import 'utils/variables';

app #unsupported-browser {
    @include respond-to-at-least(small_screen) {
        margin-left: 0;
    }
    @include respond-to(small_screen) {
        margin-left: $sidebar-width;
    }
}

#unsupported-browser {
    padding: 15px;

    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: $white;

    .icon {
        margin-bottom: 20px;
        font-size: 64px;
        color: $text-secondary-color;
    }

    .text-prim {
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        color: $text-primary-color;
    }

    .text-secondary {
        max-width: 400px;
        color: $text-primary-color;
        font-size: 13px;
        line-height: 18px;
    }
}
