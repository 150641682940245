/* ---------- Common constants and mixins ---------- */
$white: #fff;
$black: #000;
$red: #f00;
$body-color: #616265;
$version-color: #fa4616;

@mixin square($size) {
    width: $size;
    height: $size;
}

@mixin reset-margins {
    margin: 0;
    padding: 0;
}

@mixin reset-margin {
    margin: 0;
}

@mixin reset-horizontal-margin {
    margin-left: 0;
    margin-right: 0;
}

@mixin reset-list-style {
    list-style-type: none;
    list-style-image: none;
}

@mixin button($text_color, $background_color, $border_color) {
    border-radius: 5px;
    padding: 5px 14px;
    text-align: center;
    cursor: pointer;
    color: $text_color;
    background: $background_color;
    border: 1px solid $border_color;
}

.no-decoration {
    text-decoration: none;

    &:link {
        text-decoration: none;
    }

    &:visited {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }
}

.white-link {
    color: $white;

    &:link {
        color: $white;
    }

    &:visited {
        color: $white;
    }

    &:hover {
        color: $white;
    }

    &:active {
        color: $white;
    }
}

@mixin colored-link($color) {
    color: $color;

    &:link {
        color: $color;
    }

    &:visited {
        color: $color;
    }

    &:hover {
        color: $color;
    }

    &:active {
        color: $color;
    }
}
$xs-max-width: 767px;
$sm-min-width: 768px;
$sm-max-width: 991px;
$md-min-width: 992px;
$md-max-width: 1199px;
$lg-min-width: 1200px;

@mixin respond-to($media-screen) {
    @if $media-screen == phone {
        @media only screen and (max-width: $xs-max-width) {
            @content;
        }
    } @else if $media-screen == tablet {
        @media only screen and (min-width: $sm-min-width) and (max-width: $sm-max-width) {
            @content;
        }
    } @else if $media-screen == small_screen {
        @media only screen and (min-width: $md-min-width) and (max-width: $md-max-width) {
            @content;
        }
    } @else if $media-screen == large_screen {
        @media only screen and (min-width: $lg-min-width) {
            @content;
        }
    }
}

@mixin respond-to-at-least($media-screen) {
    @if $media-screen == phone {
        @media only screen and (max-width: $xs-max-width) {
            @content;
        }
    } @else if $media-screen == tablet {
        @media only screen and (max-width: $sm-max-width) {
            @content;
        }
    } @else if $media-screen == small_screen {
        @media only screen and (max-width: $md-max-width) {
            @content;
        }
    } @else if $media-screen == large_screen {
        @media only screen and (min-width: $lg-min-width) {
            @content;
        }
    }
}

@mixin col-5 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

@mixin col-xs-5 {
    @include col-5();
    width: 20%;
    float: left;
}

@mixin col-sm-5 {
    @include col-5();

    @media (min-width: $sm-min-width) {
        width: 20%;
        float: left;
    }
}

@mixin col-md-5 {
    @include col-5();

    @media (min-width: $md-min-width) {
        width: 20%;
        float: left;
    }
}

@mixin col-lg-5 {
    @include col-5();

    @media (min-width: $lg-min-width) {
        width: 20%;
        float: left;
    }
}
