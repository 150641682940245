/*
* CONTENTS:
* ALERT OVERLAY
* ALERT BOX
* SHARE FORM
* DELETE BOX
* FEEDBACK FORM
*/
@import '../utils/mixins';
@import '../utils/variables';

/* DIALOG BOX */
/* When you want to give a dialog a certain width for larger than xs screens
   you should apply it to its id.
*/

.di-padding {
    padding-left: $di-padding-inner;
    padding-right: $di-padding-inner;
}

.dialog-max {
    @include respond_to(phone) {
        width: 100%;
    }
    width: $xs-max-width;
}

.dialog-xl {
    @include respond_to(phone) {
        width: 100%;
    }
    width: 850px;
}

.dialog-lg {
    @include respond_to(phone) {
        width: 100%;
    }
    width: 700px;
}

.dialog-std {
    @include respond_to(phone) {
        width: 100%;
    }
    width: 600px;
}

.dialog-sm {
    @include respond_to(phone) {
        width: 100%;
    }
    width: 540px;
}

.dialog-xs {
    @include respond_to(phone) {
        width: 100%;
    }
    width: 360px;
}

#dialog-progress {
    @include respond_to(phone) {
        width: 100%;
    }

    @include respond_to(tablet) {
        width: $xs-max-width;
    }

    width: 900px;
    height: 470px;

    .dialog-inner {
        padding-bottom: 0;
    }
}

.vcs-ui__dialog__root {
    z-index: index($global-z, 'dialog') !important;
}

// Simulate vcs-ui styles for old dialogs
.dialog__root {
    position: fixed;
    z-index: index($global-z, 'dialog');
    
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    animation: fadein 0.3s;
}

.dialog__overlay {
    background-color: rgba(var(--black-rgb), 0.2);

    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog__overlay > div {
    @include respond-to-at-least(phone) {
        height: 100% !important;
        width: 100% !important;
        max-height: 100% !important;
        animation: none !important;
        border-radius: 0;
        margin: 0;
    }

    display: flex;
    flex-direction: column;
    
    max-height: calc(100% - 64px);
    margin: 32px;

    background-color: var(--bg-color);
    border-radius: 7px;
    border: 1px solid var(--border-color);

    box-shadow: 0 3px 6px rgba(black, 0.3);

    position: relative;
    overflow-y: auto;

    animation: animatezoom 0.3s;
}

// TODO: This is something for the webview. Test it.
.dialog-page .dialog__overlay > div {
    height: 100%;
    max-height: 100%;
    flex: 1;
    border-radius: 0;
    margin: 0;
}

.dialog-header {
    // From MUI
    flex: 0 0 auto;
    padding: 0 $di-padding-inner;

    // VCS
    display: flex;
    align-items: center;
    height: 50px;

    // Because of loading
    position: relative;

    .icon-close {
        font-size: var(--vcs-icon-size-md);
        margin-left: auto;
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
    }
}

.dialog-title {
    @include upper-case-bold(14px);
    float: left;
}

.dialog-inner {
    flex: 1 1 auto;
    overflow-y: auto;
}

/* Universal dialog structures */
.dialog-paragraph {
    /* Used to format text in dialogs
    where there's no other content. */
    padding: 0 $di-padding-inner;
    margin-bottom: 0;
    flex-shrink: 0;
}

.di-block {
    padding-left: $di-padding-inner;
    padding-right: $di-padding-inner;
    margin-top: 15px;
    margin-bottom: 15px;
}

.di-block-marg {
    margin-top: $di-block-margin-top;
    margin-bottom: $di-block-margin-bot;
}

.di-block--marg-top {
    padding-left: $di-padding-inner;
    padding-right: $di-padding-inner;
    margin-top: 15px;
}

/* DIALOG BUTTONS */
.dialog-buttons {
    flex: 0 0 auto;
    display: flex;
    padding: $di-padding-inner;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.di-btn {
    @include respond-to(phone) {
        min-width: 125px;
    }

    @include vw-btn();
    position: relative;
    padding: 0 10px;
    line-height: $di-btn-height;
    min-width: 140px;
}

.di-btn-with-icon {
    @include respond-to(phone) {
        min-width: 125px;
    }

    padding-left: 30px;

    .icon {
        position: absolute;
        top: 4px;
        left: 4px;
        font-size: var(--vcs-icon-size-md);
    }
}

.di-btn-prim {
    @include vw-btn-prim();
    order: 1;
}

.di-btn-secondary {
    @include vw-btn-secondary();
    order: 2;
}

/* File and folders listing in dialogs. */
$di-item-height: 50px;

/* Style to the ITEMS wrapper
.items {
    border-top: 1px solid $border-color;
} */

.thumb-items {
    overflow: auto;
    padding-top: 7px;
    background-color: $page-bg-color;
}

.di-list-item {
    @include asset-list-item-thumb();    

    height: $di-item-height;
    padding: 0 $di-padding-inner;
    position: relative;
    border-top: 1px solid $border-color;
    display: flex;
    align-items: center;


    .item-name {
        @include text-collapse();
        flex: 1;
        margin-left: 18px;
    }

    .item-name-duo {
        @include primary-secondary-canvas(15px, 17px, 11px, 13px);

        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2px;
    }

    .item-name-desc {
        @include primary-secondary-canvas(15px, 17px, 11px, 13px);

        .secondary {
            overflow: normal;
        }
    }
}

.di-thumb {
    @include square(24px);
    position: relative;

    .di-thumb-image {
        @include square(24px);
    }

    .di-thumb-image--background {
        @include fit-background--cover; 
    }

    .di-default-icon {
        font-size: var(--vcs-icon-size-md);
    }

    .svg-file-icon {
        position: unset !important;
    }
}


/* LOADER ANIMATION */
/* Meant to be in the header */
.di-loading {
    height: 1px;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    overflow: hidden;

    &::before {
        display: block;
        position: absolute;
        content: '';
        left: -200px;
        width: 200px;
        height: 4px;
        background-color: $vw-annual-color;
        animation: loading 2s linear infinite;
    }
}


#dialog-3d-generate,
#dialog-revit-export {
    font-size: 15px;
    color: var(--text-primary-color);

    .option {
        @include respond-to(phone) {
            display: flex;
            flex-direction: column;

            .vw-checkbox-text {
                margin-top: 5px;
            }
        }

        display: grid;
        grid-template-columns: 2fr 4fr;
        grid-column-gap: 15px;
    }

    .save-as {
        grid-template-columns: 1fr 5fr;
    }

    .items {
        .di-list-item:first-child {
            border-top: unset !important;
        }
    
        .di-list-item:last-child {
            border-bottom: 1px solid var(--border-color);
        }
    }

    .vw-checkbox-text .text {
        margin-left: 3px;
    }
}

#dialog-photogrammetry-job {
    font-size: 15px;
    color: var(--text-primary-color);

    .option {
        @include respond-to(phone) {
            display: flex;
            flex-direction: column;

            .vw-checkbox-text {
                margin-top: 5px;
            }
            
            .vw-select {
                flex: 1;
            }
        }

        display: grid;
        grid-template-columns: 2fr 4fr;
        grid-column-gap: 15px;
    }

    .save-as {
        grid-template-columns: 1fr 5fr;
    }

    .items {
        .di-list-item:first-child {
            border-top: unset !important;
        }
    
        .di-list-item:last-child {
            border-bottom: 1px solid var(--border-color);
        }
    }

    .vw-checkbox-text .text {
        color: var(--text-primary-color);
        margin-left: 3px;
    }

    .vw-select {
        min-width: 250px;
        background-color: white;
        border: 1px solid $border-color;
    }
}
