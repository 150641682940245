@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

#welcome-tour {
    z-index: 25;
}

.wt-contents {
    height: 100%;
    display: flex;
}

.wt-sidepanel {
    @include respond-to(phone) {
        display: none;
    }

    display: flex;
    flex-direction: column;
    width: 210px;
    background-color: $border-color;

    a > span {
        padding: 0 15px;
    }
}

.wt-layout {
    @include respond-to(phone) {
        width: 100%;
        flex: none;
    }

    flex: 1;
    display: flex;
    flex-direction: column;
}

.wt-header {
    height: 50px;
    padding: 8px;
    
    .icon-close {
        float: right;
    }
}

.wt-page {
    @include respond-to(phone) {
        display: flex;
        flex-direction: column;
    }
}

.wt-image {
    @include respond-to(phone) {
        align-self: center;
    }

    height: 217px;
}

.wt-text-content {
    padding: 10px 20px;

    .wt-title {
        font-size: 14px;
        font-weight: bold;
        color: black;
        margin-bottom: 10px;
    }

    .wt-descr {
        font-size: 13px;
    }

    li {
        font-size: 12px;
    }
}

.btn-next {
    margin-top: 52px;
    padding: 5px 40px;
    align-self: center;
    text-transform: initial;
}

.btn-done {
    display: inline-block;
    align-self: flex-start;
    padding: 5px 40px;
    margin-top: 20px;
    margin-left: 105px;
    text-transform: initial;
}

.wt-next-steps {
    display: flex;
    flex-direction: column;
}

.wt-next-step {
    @include link-no-decoration($text-primary-color);

    margin: 15px 0;
    display: flex;
    align-items: center;

    &:hover {
        .title,
        .info {
            transition: color 0.4s ease;
            color: $vw-annual-color;
        }
    }

    .icon {
        font-size: 50px;
        margin: 0 30px;
    }

    .info {
        display: flex;
        flex-direction: column;
    }

    .title {
        color: black;
        font-weight: bold;
    }
}

.slick-slider {
    @include respond-to(phone) {
        display: flex;
    }

    flex: 1;
    width: 100%;
    display: none;

    .slick-list {
        width: 100%;
    }

    .icon-nav {
        z-index: 1;
        position: absolute;
        bottom: 10px;
    }

    .icon-right-arrow {
        right: 10px;
    }

    .icon-left-arrow {
        left: 10px;
    }

    .slick-dots {
        display: flex !important;
        align-items: center;
        justify-content: center;
        
        bottom: 0;
        height: 50px;
        background-color: $border-color;
    }

    .slick-dots > li > button:before {  // stylelint-disable-line
        font-size: 12px;
        color: #d4d4d4;
        opacity: 1;
    }

    .slick-dots > li.slick-active > button:before {  // stylelint-disable-line
        color: $vw-annual-color;
    }
}
