@import 'collapsible';


.fixed {
    position: fixed !important;
}

/* If you put this class on a row, all it's directly
nested col-* element will have no padding */
.no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.no-padding {
    padding: 0 !important;
}

.w100per {
    width: 100%;
}

.w50per {
    width: 50%;
}

/* MARGINS */
@mixin mtb($value) {
    margin-top: $value;
    margin-bottom: $value;
}

@mixin mlr($value) {
    margin-left: $value;
    margin-right: $value;
}

.mtb15 {
    @include mtb(15px);
}

.mlr15 {
    @include mlr(15px);
}

.mr15 {
    margin-right: 15px;
}

.ml15 {
    margin-left: 15px;
}

.no-margin {
    margin: 0 !important;
}

.zindex-1 {
    z-index: 1;
}

.opacity-0 {
    opacity: 0;
}

.h-30 {
    height: 30px;
}

.annual-color {
    color: $vw-annual-color;
}

.bold {
    font-weight: bold;
}

.success {
    color: $vw-annual-color;
}

.error {
    color: $vw-red !important;
}

.flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.flex-0 {
    flex: 0;
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-col {
    flex-direction: column;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

$status-canceled: #b2b3b4;

.canceled {
    color: $status-canceled;
}

.text-sec-12 {
    font-size: 12px;
    color: $text-secondary-color;
}

.text-sec-11 {
    font-size: 11px;
    color: $text-secondary-color;
}

.text-color--yellow {
    color: $vw-yellow;
}

.text-collapse {
    @include text-collapse;
}

.text-break {
    @include text-break;
}

.vam {
    vertical-align: middle;
}

.u {
    text-decoration: underline;
}

.icon16 {
    font-size: 16px !important;
}

.icon-md {
    font-size: var(--vcs-icon-size-md) !important;
}

@mixin disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
    pointer-events: none;
}

.disabled {
    @include disabled;
}

.invisible {
    visibility: hidden;
}

.pointer-events-none {
    pointer-events: none;
}

.relative {
    position: relative;
}

/* Let's say you want to make a whole area .disabled
Make the area with position: relative;
Add this <div class="disabling-block"></div> to the BOTTOM of the area (for z-index reasons)
It will cover the whole area and make it disabled.
Add .disabled class to the background area to make it with opacity 0.5.
*/
.disabling-block {
    @include disabled;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.hover-icon {
    @include vw-hover-animation($text-primary-color);

    &:hover {
        cursor: pointer;
    }
}

.vw-btn {
    @include vw-btn();
}

.vw-btn-default {
    @include vw-btn();
    padding: 5px 30px;
}

.vw-btn-xs {
    @include vw-btn();
    height: 18px;
    padding: 0 10px;
    font-size: 10px;
    line-height: 18px;
}

.vw-btn-sm {
    @include vw-btn();
    padding: 2px 20px;
}

.vw-btn-round {
    border-radius: 40px;
}

.vw-btn-di-input {
    @include vw-btn();
    padding: 7px 20px;
}

.vw-btn-prim {
    @include vw-btn-prim();
}

.vw-btn-secondary {
    @include vw-btn-secondary();
}

/* ANIMATIONS */
/*stylelint-disable*/
@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes animatezoom {
    from { transform: scale(0.85); }
    to {
        transform: scale(1);
        transform: translateZ(0);
        fliter: blur(0);
    }
}

.inactive-link {
   pointer-events: none;
   cursor: default;
}

/* TABS */

$notify-tabs-height: 40px;

.vw-tabs {
    flex-shrink: 0;
    padding: 0;
    margin: 0;
    line-height: $notify-tabs-height;
    border-bottom: 1px solid $border-color;
    text-transform: uppercase;
    list-style-type: none;

    display: flex;

    li {
        margin-bottom: -1px;
        border-right: 1px solid $border-color;
    }

    .tab {
        @include link-no-decoration($text-primary-color);
        border-bottom: 1px solid $border-color;
        border-top: 1px solid $border-color;
        flex-shrink: 0;
        display: block;
        padding: 0 17px;
        white-space: nowrap;
    }

    .active {
        .tab {
            border-bottom: 1px solid white;
        }
    }

}

.hr {
    margin: 0;
    width: 100%;
    border-bottom: 1px solid rgba($black, 0.1);
}

.validation-block {
    min-height: 17px;
}

/* Simple checkboxes/radio buttons with text next to them */
@mixin vw-checkbox-text {
    font-size: 12px;

    label {
        margin-bottom: 0;
        display: flex;
    }

    input[type=checkbox], input[type=radio] {
        position: relative;
        vertical-align: middle;
        margin: 0;
        margin-right: 2px;
        margin-top: 2px;
    }
}

.vw-checkbox-text {
    @include vw-checkbox-text;

    .text {
        font-size: 14px;
    }

    input[type=checkbox], input[type=radio] {
        margin-top: 3px;
    }
}

.vw-checkbox-text-prim {
    @include vw-checkbox-text;

    .text {
        color: $text-primary-color;
        font-weight: 500;
    }
}

.vw-checkbox-text-sec {
    @include vw-checkbox-text;

    .text {
        color: $text-secondary-color;
    }
}

.vw-checkbox-small {
    @include vw-checkbox-text;

    .text {
        font-size: 11px;
        margin-right: 5px;
    }
}

@mixin vw-radio-text {
    display: inline-block;
    white-space: nowrap;
    font-size: 12px;

    label {
        margin-bottom: 0;
    }
}

.vw-radio {
    padding: 3px 0;
    position: relative;

    display: inline-flex;
    margin-right: 10px;

    color: $text-primary-color;
    cursor: pointer;

    input[type=radio] {
        position: absolute;
        visibility: hidden;
    }

    .check {
        height: 17px;
        width: 17px;
        margin-right: 7px;

        position: relative;
        display: block;

        border: 3px solid $text-secondary-color;
        border-radius: 100%;
        transition: border 0.4s linear;
        z-index: 5;
    }

    .check::before {
        display: block;
        position: absolute;
        content: '';
        border-radius: 100%;
        height: 7px;
        width: 7px;
        top: 2px;
        left: 2px;
        margin: auto;
        transition: background 0.4s linear;
    }

    input[type=radio]:checked ~ .check {
        border: 3px solid $text-primary-color;
    }

    input[type=radio]:checked ~ .check::before {
        background: $text-primary-color;
    }
}

.vw-radio.inactive {
    .inside {
        width: 13px;
        height: 13px;
        border-radius: 100%;
        margin-top: -1px;
        margin-left: -1px;
        background-color: $text-secondary-color;
    }
}

.vw-radio-text {
    @include vw-radio-text;

    .text {
        font-size: 14px;
    }
}

.vw-radio-text-prim {
    @include vw-radio-text;

    .text {
        color: $text-primary-color;
        font-weight: 500;
    }
}

.vw-radio-group {
    line-height: 20px;
}

.hidden {
    display: none;
}

// Need to add height to the elements in the collapsible to make it transition smoothly
.expandable--vert {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);

    &.expanded {
        max-height: 400px;
        transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    }
}

.ctx-menu__sep {
    border-bottom: 1px solid #e9e9e9;
    height: 1px;
}

.gl-create-actions-widget {
    position: fixed;
    bottom: 10px;
    right: 10px;

    width: 46px;
    height: 46px;
    border-radius: 100%;
    box-shadow: $thumb-shadow;

    font-size: 32px;
    color: white;
    text-align: center;
    background-color: $vw-annual-color;
    z-index: index($main-z, 'gl-create-actions-widget');

    .icon {
        position: absolute;
        top: 7px;
        left: 7px;
        transform: rotate(180deg);
    }
}

/* LOADING SPINNER */
@keyframes loading-rolling {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.lds {
    border-radius: 50%;
    animation: loading-rolling 1s linear infinite;

    border-width: 5px;
    border-style: solid;
    border-color: $vw-annual-color;
    border-top-color: transparent;
    z-index: index($main-z, 'lds');
}

/* When the spinner is in container that has to be filled. Add a position prop to the container. */
.lds--container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.lds--small {
    width: 11px;
    height: 11px;

    border-width: 2px;
}

.lds--grey {
    border-color: $text-secondary-color;
    border-top-color: transparent;
}

.lds--large {
    width: 40px;
    height: 40px;
}

.box-shadow {
    @include box-shadow;
}
